import {Link} from "react-router-dom";

const AboutUsContent = () => {
    return <>
        <div>
            <p className="text-start"><strong>Land of Gods</strong> is a survival Minecraft
                server that offers players a community driven, survival experience with an Egyptian theme. Our approach
                to survival caters to a variety of different play styles; for those who are looking for a cooperative
                experience, we have restrictions on raiding/griefing and PvP, with security features such as BlockLocker
                and gameplay mechanics such as McMMO to ensure you have the best possible experience.</p><p
            className="text-start">Additionally, for those who are interested in testing their skills and inventory
            in a competitive environment, player vs player will be available in the not too distant future. PvP will
            be implemented as an optional feature for specific ranks and above. We pride ourselves on what we call
            “Play-to-Win”; all server ranks are obtainable through in-game currency, which is in turn obtainable
            through continued play and special events. Each rank will provide access to new abilities, and special
            Perks.</p>
            <div className="dropdown-divider">
                <hr/>
            </div>
            <h2 className="font-weight-bold text-start">How to protect your valuables</h2><p
            className="text-start">We have many systems in place to ensure your valuables are protected. We
            have utilised custom container and unnatural spawner protection to prevent unauthorised access by
            default. You may also use <a href="https://youtu.be/EaCHTiPNGiQ">BlockLocker</a> to claim any
            additional objects (such as doors, gates, trapdoors) and land claiming via <a
                href="https://www.youtube.com/watch?v=VDsjXB-BaE0">GriefPrevention</a>. We will be uploading a
            guide in the future.</p>
            <hr/>
            <h2 className="text-start font-weight-bold">Ranks (info and tips):</h2>
            <ul>
                <li className="text-start">They are obtainable through in-game currency.</li>
                <li className="text-start">You access our rankup GUI via the /rankup command.</li>
                <li className="text-start">Our ranks have prerequisite conditions that need to be met.</li>
                <li className="text-start">Partner with other players and grow together. Work towards common
                    goals.
                </li>
            </ul>
            <p className="text-start">As all ranks are obtainable through ingame currency, you should see
                yourself partnering with other players to grow together. It is very common to see many players
                working together to achieve shared goals. Therefore, working as a team to successfully complete
                difficult tasks and succeed in events is highly encouraged to bring about a friendly and close
                (yet competitive) community.</p>
            <div className="dropdown-divider">
                <hr/>
            </div>
            <h2 className="text-start font-weight-bold">Basic commands:</h2><p className="text-start">We have
            a tonne of useful commands, most of which can be found on our <Link to={"/useful-commands"}>useful commands page</Link>.</p><p
            className="text-start">Internally, we consider this iteration of Land of Gods as an early Beta.
            We are continuously learning and adapting to your needs. Hence, we have implemented methods of
            expressing concern of bugs and server inconsistencies. requesting improvements in the form of
            new features. Our <a href="https://discord.landofgods.net">discord</a>&nbsp;has sections
            relating to reports (both for players and bugs), appeals and suggestions for our server.
            Furthermore, if you have any requests or suggestions or want to report a bug/player, our discord
            is the best place to do so.</p>
            <p><Link className="btn btn-primary" to={"/"}>Stay tuned</Link></p>
        </div>
    </>
}
export default AboutUsContent;