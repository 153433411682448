import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons/faHouse";
import {faCheckToSlot} from "@fortawesome/free-solid-svg-icons/faCheckToSlot";
import {faScaleBalanced} from "@fortawesome/free-solid-svg-icons/faScaleBalanced";
import {faQuestion} from "@fortawesome/free-solid-svg-icons/faQuestion";
import HomeContent from "../page/HomeContent";
import RulesContent from "../page/RulesContent";
import VoteContent from "../page/VoteContent";
import AboutUsContent from "../page/AboutUsContent";


const pages = [
    {
        enabled: true,
        hideTitle: true,
        icon: <FontAwesomeIcon icon={faHouse} className={"me-2"}/>,
        title: "Home Page",
        homepage: true,
        nav: "Home",
        url: '/',
        custom: true,
        content: <HomeContent/>
    },
    {
        enabled: true,
        icon: <FontAwesomeIcon icon={faCheckToSlot} className={"me-2"}/>,
        title: "Voting for our server",
        nav: "Vote",
        url: '/voting',
        content: <VoteContent/>
    },
    {
        enabled: true,
        icon: <FontAwesomeIcon icon={faScaleBalanced} className={"me-2"}/>,
        title: "Server Guidelines (Rules of the server)",
        nav: "Rules",
        url: '/rules',
        content: <RulesContent/>
    },
    {
        enabled: true,
        icon: <FontAwesomeIcon icon={faQuestion} className={"me-2"}/>,
        title: "About Us",
        nav: "About Us",
        url: '/about-us',
        content: <AboutUsContent/>
    }
]
export default pages;