const UsefulCommands = () => {
    return <div className="row mt-3">
        <div className="col col-sm-12">
            <div className="card border-0">
                <div className="card-header bg-white"><h2 className="font-weight-bold">Useful Commands</h2></div>
                <div className="card-body"><p className="has-text-align-left">On the Land of Gods server, you will work
                    your way up from our starter rank (Slave) to our most prestigious rank (Oracle). The slave rank is
                    the very first rank which is given out to players who join our server for the first time. This rank
                    doesn’t have any prefixes, this is intentional, you receive status via the /rankup system.</p>
                    <div
                        className="wp-block-pb-accordion-item c-accordion__item js-accordion-item no-js is-open p-2 border"
                        data-initially-open="true" data-click-to-close="true" data-auto-close="true" data-scroll="true"
                        data-scroll-offset="0"><h2 id="at-2950" className="c-accordion__title js-accordion-controller"
                                                   role="button">General Commands</h2>
                        <div id="ac-2950" className="c-accordion__content">
                            <table className="table mt-2 mb-0">
                                <thead>
                                <tr>
                                    <th scope="col">Command</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Alias</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td> /help</td>
                                    <td> Opens a useful help gui.</td>
                                    <td><br/></td>
                                </tr>
                                <tr>
                                    <td> /rules</td>
                                    <td> Sends you information about our server rules.</td>
                                    <td><br/></td>
                                </tr>
                                <tr>
                                    <td> /msg</td>
                                    <td> Send a private message to a player.</td>
                                    <td> tell</td>
                                </tr>
                                <tr>
                                    <td> /reply</td>
                                    <td> Reply to a private message.</td>
                                    <td> r</td>
                                </tr>
                                <tr>
                                    <td> /home</td>
                                    <td> teleport to your safe home locations.</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td> /sethome</td>
                                    <td> Set a safe home location.</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td> /back</td>
                                    <td> Go back to your previous teleport/death location.</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td> /kit</td>
                                    <td> Grab or preview a kit.</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td> /warp <br/></td>
                                    <td> Teleport to one of our predefined warp regions.</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td> /tpa</td>
                                    <td> Request to teleport to a player.</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td> /tpahere</td>
                                    <td> Request a player to teleport to you.</td>
                                    <td><br/></td>
                                </tr>
                                <tr>
                                    <td> /shakeitoff</td>
                                    <td> Dismount any mob/player that is riding you.</td>
                                    <td><br/></td>
                                </tr>
                                <tr>
                                    <td> /wild</td>
                                    <td> Teleport to the wild portal (It randomly teleports you around the world).</td>
                                    <td><br/></td>
                                </tr>
                                <tr>
                                    <td> /ranks</td>
                                    <td> Shows you a list of ranks available through /rankup.</td>
                                    <td><br/></td>
                                </tr>
                                <tr>
                                    <td> /rankup</td>
                                    <td> Open up the rankup gui.</td>
                                    <td><br/></td>
                                </tr>
                                <tr>
                                    <td> /shop</td>
                                    <td> Teleports you to the server shop.</td>
                                    <td><br/></td>
                                </tr>
                                <tr>
                                    <td> /vote</td>
                                    <td> Vote daily to get cool rewards/perks.</td>
                                    <td><br/></td>
                                </tr>
                                <tr>
                                    <td> /cr claim</td>
                                    <td> Claim crate keys that you receive via /vote.</td>
                                    <td><br/></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="wp-block-pb-accordion-item c-accordion__item js-accordion-item no-js p-2 border"
                         data-initially-open="false" data-click-to-close="true" data-auto-close="true"
                         data-scroll="true" data-scroll-offset="0"><h2 id="at-2951"
                                                                       className="c-accordion__title js-accordion-controller"
                                                                       role="button">Claim Commands</h2>
                        <div id="ac-2951" className="c-accordion__content">
                            <table className="table mt-2 mb-0">
                                <thead>
                                    <tr style={{height: "27px"}}>
                                        <th style={{height: "27px"}} scope="col">Command</th>
                                        <th style={{height: "27px"}} scope="col">Description</th>
                                        <th style={{height: "27px"}} scope="col">Alias</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{height: "27px"}}>
                                        <td style={{height: "10px"}}>/claim</td>
                                        <td style={{height: "10px"}}>Claim a region of land.</td>
                                        <td style={{height: "10px"}}>&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default UsefulCommands;