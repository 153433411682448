import React from 'react';
import NavigationPages from '../Data/NavigationPages';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {NavLink} from "react-router-dom";

const Navigation = ({currentPage}) => {

    const copyServerIp = () => {
        const textToCopy = 'landofgods.net';
        const textField = document.createElement('textarea');
        textField.value = textToCopy;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        document.body.removeChild(textField);
        toast.success("Copied ip: landofgods.net");
    }

    return (
        <>
            <ToastContainer />
            <nav className="navbar navbar-expand-lg navbar-light bg-white rounded shadow-sm px-2">
                <div className="container">
                    <a className="navbar-brand" href={"/"}>
                        Land of Gods
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav">
                            {NavigationPages.map((page, index) =>
                                    !!page.enabled && <li
                                        key={index}
                                        className={"nav-item"}>
                                        <NavLink className={`nav-link text-center px-4 ${currentPage?.title === page.title ? 'active border-bottom' : ''}`}
                                           to={page.url}>
                                            {page.icon}{page.nav}
                                        </NavLink>
                                    </li>
                            )}
                        </ul>
                        <div className={"d-flex justify-content-center pt-2 pt-sm-0 ms-0 ms-md-3"}>
                            <button
                                className="btn btn-primary rounded"
                                data-clipboard-text="landofgods.net" data-toggle="modal" data-target="#ExtraInfo"
                                onClick={copyServerIp}>
                                <FontAwesomeIcon icon={faCopy} className={"me-2"}/>
                                Copy Server Ip
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="modal fade" id="ExtraInfo" tabIndex="-1" role="dialog" aria-labelledby="modalL"
                 style={{display: "none"}} aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header"><h5 className="modal-title" id="modalL">Thank you for choosing
                            Landofgods.net</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body"> You have selected to copied our
                            ip: <kbd>landofgods.net</kbd><br/> Check out our <a
                                href="https://discord.landofgods.net">discord</a> and don't forget to <a
                                href="https://landofgods.net/voting">vote</a>.</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navigation;
