import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";

const VoteContent = () => {
    const [searchParams]  = useSearchParams();
    const [username, setUsername] = useState();
    useEffect(()=> {
        setUsername(searchParams?.get('mcuser'));
    }, [searchParams]);
    return <>
        <div>
            {!!username && <>Welcome {username}</> }
            <p className="text-start">Voting is an essential part of <a
                href="http://209.222.101.66">Land of Gods</a>. Here you will find a list of voting websites and reasons
                to vote, along with general advice. Voting can be utilised to get keys to our vote crate and
                automatically enter you into our vote party.</p>
                <div className="dropdown-divider">&nbsp;</div>
                <h3 className="h5 font-weight-bold">Where can I vote?</h3><h6>If you vote on three of these websites you
                    shall receive a diamond and entry into the vote party. Vote on all of the websites and you shall
                    receive a Vote crate key!</h6>
                <p className="votelinks"><a href={`https://www.planetminecraft.com/server/land-of-gods-3680663/vote${!!username ? ("?username="+username) : ""}`}
                                            target="_blank" rel="noreferrer">
                    <button className="btn btn-primary mt-2" type="button">Vote on PlanetMinecraft</button>
                </a><br/><a href={`https://minecraft-server-list.com/server/421395/vote`} target="_blank" rel="noreferrer">
                    <button className="btn btn-primary mt-2" type="button">Vote on Minecraft-Server-List</button>
                </a><br/><a href={`https://minecraft-mp.com/server/160867/vote/${!!username ? ("?username="+username) : ""}`} target="_blank" rel="noreferrer">
                    <button className="btn btn-primary mt-2" type="button">Vote on Minecraft-MP</button>
                </a><br/><a href={`http://topg.org/Minecraft/in-436244${!!username ? ("-"+username) : ""}`} target="_blank" rel="noreferrer">
                    <button className="btn btn-primary mt-2" type="button">Vote on Topg</button>
                </a></p>
                <div className="dropdown-divider">&nbsp;</div>
                <h3 className="h5 font-weight-bold">Why should I vote?</h3><p className="text-start">Voting is one of the
                    best ways to help out our server. It helps our server grow and can be done with minimal effort at
                    your expense. We really appreciate you taking the time to vote for our server. Hence, we have made
                    incentives that reward you for supporting our server.</p><p className="text-start">Our reward
                    incentives are strategic and are designed to not be “overpowered”. The incentives that you should
                    expect to see include (but are not limited to); Vote crates, Hedj and VoteParties. You will receive
                    a vote key and be entered into the vote party upon voting on three out of the four voting websites.
                    By voting on the final website you will receive one diamond.</p>
                <div className="dropdown-divider">&nbsp;</div>
                <h3 className="h5 font-weight-bold">What are crates?</h3><p className="text-start">Crates are designed to
                    reward you for showing your support. As of Friday the 1st of December, there are three crates. These
                    crates can be found at the /warp crates section of spawn. Find the respective crate that is
                    associated with your key. You can view the contents of this crate by left clicking the crate
                    (punch).</p><p className="text-start">When engaging with these crates, ensure that you have space in
                    your inventory to receive items. When you are ready to receive your reward simply right click the
                    crate with the associated key. A fancy animation will occur and you will receive a reward. To keep
                    things interesting, not all items in the crate are positive. There is potential to win “dud” items
                    such as cakes. Nonetheless, it is all in good spirit. We hope you enjoy the winnings.</p><p
                    className="text-start">If you find any issues with the crates (such as broken items, or unreasonable
                    chances) please don’t hesitate to contact our staff team over on the discord. Party crates are
                    really interesting crates. They are filled with extra special goodies that satisfy your win
                    craving.</p>
        </div>
    </>
}
export default VoteContent;