import Navigation from "./Navigation";
import {Outlet, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import NavigationPages from "../Data/NavigationPages";

const Header = () => {

    const location = useLocation();

    // Define state to hold the current page
    const [currentPage, setCurrentPage] = useState(undefined);

    useEffect(() => {
        let pageName;
        const foundPage = NavigationPages.find((page) => page.url === location.pathname);
        if (foundPage) {
            setCurrentPage(foundPage);
            pageName = foundPage.title;
        } else {
            pageName = location.pathname?.substring(1).replaceAll('-', " ");
            setCurrentPage(undefined);
        }
        document.title = `Land of Gods - ${pageName}`;
    }, [location.pathname]);

    return <div className={"container bg-light min-vh-100"}>
        <Navigation currentPage={currentPage}/>
        {
            !!currentPage && !currentPage.custom && <div className={"bg-white p-4 mt-3 shadow-sm rounded"}>
                {
                    !currentPage?.hideTitle &&
                    <h2 className={"text-center text-sm-start border-bottom pb-2"}>{currentPage?.title}</h2>
                }
                {
                    !!currentPage && currentPage.content
                }
            </div>
        }
        {
            !!currentPage?.custom && currentPage.content
        }
        {
            !currentPage && <Outlet/>
        }
    </div>
}

export default Header;