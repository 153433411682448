const RulesContent = () => {
    return <>
        <div>
            <p className="justify">This is the new <a rel="noopener noreferrer"
                                                      href="https://landofgods.net"
                                                      target="_blank">Land of Gods</a> rule
                page. Firstly, we would like to thank you for taking the time to read this page. These rules are
                important to our server and help uphold our amazing community. Therefore, you play a vital role in
                maintaining a safe, respectable and fun server. Please note, you are responsible for your account.
                Therefore, all infringements that arise following the enforcement of the following rules, are applied to
                the associated account.</p><p className="justify">We (Land of Gods Staff) reserve the right to enforce
            these rules whichever way we see fit. The verdict of staff is final. Provided it is justified, staff
            reserve the right to punish for actions that aren’t explicitly stated in the rules.</p>
            <hr/>
            <h3 id="mce_2">General Rules</h3>
            <ol className="text-start">
                <li>You are responsible for your own account.</li>
                <li>Ban evading using alternate account will result in an IP-ban. Unban applications can be made
                    on our <a rel="noreferrer noopener" aria-label="discord (opens in a new tab)"
                              href="https://discord.landofgods.net" target="_blank">discord</a>!
                </li>
                <li>Use common sense. If it is wrong don’t do it.
                    <ul>
                        <li>It would be extremely difficult to list out all the possible rule violations.</li>
                    </ul>
                    <ul>
                        <li>Use your intuition to understand what is breaking the rules and/or pushing
                            boundaries (e.g. instigating).
                        </li>
                    </ul>
                </li>
            </ol>
            <hr/>
            <h3>Chat Rules</h3><p>(Apply to all our chat spaces including
            discord)</p>
            <ol className="text-start">
                <li>Advertising another server is strictly prohibited. <strong>(Warning/Temporary
                    ban)</strong>
                    <ul>
                        <li>This includes mentioning server names.</li>
                    </ul>
                </li>
                <li>Vulgar language is strictly prohibited. <strong>(Warning/Ban)</strong></li>
                <li>No commentary about Politics, Tragedies, and any other sensitive
                    topics. <strong>(Warning/mute)</strong></li>
                <li>No inappropriate behaviour and/or drama. <strong>(Warning/Mute)</strong></li>
                <li>Inappropriate usernames are not allowed. <strong>(Kick/Temporary ban)</strong></li>
                <li>Spamming is not allowed. <strong>(Warning/Mute)</strong>
                    <ul>
                        <li>Including excessively asking for; items, ranks, positions.</li>
                    </ul>
                </li>
                <li>Chat must remain in english. <strong>(Warning/Mute)</strong>
                    <ul>
                        <li>Non-English languages can trigger auto-punishment false positives.</li>
                    </ul>
                </li>
                <li>Keep caps to a minimum. Excess use will result in a
                    mute. <strong>(Warning/Mute)</strong></li>
                <li>Don’t leak any private information about yourself or any other player, even if you know
                    them IRL. <strong>(Warning/Mute/Ban)</strong></li>
            </ol>
            <hr/>
            <h3>Gameplay Rules</h3>
            <ol className="text-start">
                <li>Inappropriate skins are not allowed. <strong>(Kick/Temporary ban)</strong></li>
                <li>Abusing global command (e.g. /time) is not allowed <strong>(Individual removal of
                    perk)</strong></li>
                <li>Any form of griefing is not allowed. <strong>(Warning/Jail/Temporary ban)</strong>
                    <ul>
                        <li><span className="tadv-format-panel font-weight-bold">Griefing includes but is not limited to:</span>
                            <ul>
                                <li>Destroying a build that you didn’t create, without consent.</li>
                                <li>Stealing from other players, ask before you take.</li>
                                <li>Scamming <span className="tadv-format-panel font-italic">(includes renaming items to appear valuable, e.g. keys)</span>
                                </li>
                                <li>Creating player traps that kill players.</li>
                                <li>Abusing other players and exploiting resources that you don’t own
                                    without consent.
                                </li>
                                <li>Modifying a build, without consent, regardless of its claim state.
                                </li>
                                <li>Large hole bases (e.g. digging out chunks)</li>
                                <li>Random one block wide pillars.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>Any form of exploitation is strictly prohibited. <strong>(Warning/1 – 30 day
                    Temporary ban/Permanent ban)</strong>
                    <ul>
                        <li>Reporting an exploit/bug yields a reward equivalent to the severity.</li>
                        <li><span
                            className="tadv-format-panel font-weight-bold">Forms of exploitation include:</span>
                            <ul>
                                <li>Duplicating in game money/items.</li>
                                <li>Abusing bugs that could harm the server, other players, the
                                    economy.
                                </li>
                                <li>Using anti-afk systems to bypass the afk check.</li>
                                <li>Using macros to gain strategical advantages.</li>
                                <li>Automated machine abuse including, but not limited to:
                                    <ul>
                                        <li>Designs that cause large server TPS drops.</li>
                                        <li>Designs in public spaces that cause large FPS drops.</li>
                                        <li>Designs intended to keep chunks permanently loaded.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>You <strong>MUST</strong> have permission to build close to another users
                    claim. <strong>(Warning/Jail)</strong>
                    <ul>
                        <li><strong>150 blocks</strong> – Overworld</li>
                        <li><strong> 60 blocks</strong> – Nether</li>
                        <li><strong> 4 blocks</strong> – Marketplaces</li>
                    </ul>
                </li>
                <li>You <strong>MUST</strong> use the /resources world for overworld resource
                    tunnelling. <strong>(Warning/Jail)</strong></li>
                <li>You <strong>MUST</strong> physically claim land in order to maintain ownership.
                    <ul>
                        <li>“Physically claim” means golden shovel land claims, <strong>not
                            signs</strong>.
                        </li>
                        <li>If a claim expires, the player still maintains ownership of their builds on
                            the land.
                        </li>
                    </ul>
                </li>
            </ol>
            <hr/>
            <h3>Client Modification Rules</h3>
            <ol className="text-start">
                <li>The use of a hack, cheat, script or type of gameplay modifying utility is
                    strictly prohibited. <strong>(Permanent ban)</strong></li>
                <li>Mods that provide strategic advantages are not allowed. <strong>(Permanent
                    ban)</strong>
                    <ul>
                        <li>This includes but is not limited to; X-Ray Mods/Resource Packs and any
                            utility within a hacked client.
                        </li>
                        <li>You may use additions that only provide performance improvements, such
                            as Optifine.
                        </li>
                    </ul>
                </li>
            </ol>
            <hr/>
            <h3>Warning Punishments</h3>
            <ul className="text-start">
                <li><strong>Warning 1</strong> – Temporary Mute <strong>(60 minutes)</strong>.
                </li>
                <li><strong>Warning 2</strong> – Temporary Jail <strong>(1 day)</strong> +
                    Temporary Mute<strong> (3 day)</strong>.
                </li>
                <li><strong>Warning 3</strong> – Temporary Ban <strong>(7 day)</strong>.</li>
                <li><strong>Warning 4</strong> – Temporary Ban<strong> (30 day)</strong>.</li>
                <li><strong>Warning 5</strong> – Permanent Ban.</li>
            </ul>
            <hr/>
            <p className="text-center font-weight-bold">By
                joining our server, you automatically accept our terms. You also accept that
                these rules are subject to change at any given time with or without
                notification.</p>
            <p className={"text-center"}><em>Last Edited: 3rd Feb 2022 (To clarify Gameplay Rule #5
                punishment and introduce rules regarding resource tunnelling)</em></p>
        </div>
    </>
}
export default RulesContent;