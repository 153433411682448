import React, { useState, useEffect } from 'react';
const Carousel = () => {
    const [imagePaths, setImagePaths] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        // Use require.context to dynamically load images from the 'carousel' folder
        const carouselImages = require.context('../carousel', false, /\.(jpg|jpeg|png|gif|svg)$/);

        // Get an array of all image file paths
        const paths = carouselImages.keys().map((imagePath) => carouselImages(imagePath));

        setImagePaths(paths);
    }, []);

    return (
        <div>
            <div id="myCarousel" className="carousel slide" data-ride="carousel">
                {/* Indicators */}
                <ol className="carousel-indicators">
                    {imagePaths.map((_, index) => (
                        <li
                            key={index}
                            data-target="#myCarousel"
                            className={index === currentSlide ? 'active' : ''}
                        ></li>
                    ))}
                </ol>

                {/* Slides */}
                <div className="carousel-inner">
                    {imagePaths.map((imagePath, index) => (
                        <div
                            key={index}
                            className={`carousel-item ${index === currentSlide ? 'active' : ''}`}
                        >
                            <img src={imagePath}
                                 className={"w-100 rounded"}
                                 style={{maxHeight: "450px", minHeight:"200px", objectFit: "cover"}}
                                 alt={`Slide ${index + 1}`}
                                 loading={"lazy"}
                            />
                        </div>
                    ))}
                </div>

                {/* Controls */}
                <button
                    className="carousel-control-prev"
                    data-slide="prev"
                    onClick={() =>
                        setCurrentSlide((currentSlide - 1 + imagePaths.length) % imagePaths.length)
                    }
                >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </button>
                <button
                    className="carousel-control-next"
                    data-slide="next"
                    onClick={() => setCurrentSlide((currentSlide + 1) % imagePaths.length)}
                >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </button>
            </div>
        </div>
    );
}

export default Carousel;