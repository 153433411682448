import './App.css';
import Header from "./components/Header";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider
} from "react-router-dom";
import NavigationPages from "./Data/NavigationPages";
import UsefulCommands from "./page/UsefulCommands";
import FallbackPage from "./page/FallbackPage";
import ErrorBoundary from "./boundary/ErrorBoundary";

function App() {

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path={"/"} element={<Header/>}>
                <Route path={"/useful-commands"} element={<UsefulCommands/>}/>
                {
                    NavigationPages.map((page, index) => {
                        return <Route
                            key={index}
                            element={page.content}
                            {...(index === 0 && {index: true})}
                            {...(page.url && {path: page.url})}>
                        </Route>
                    })
                }
            </Route>
        )
    );
    return <div className={"min-vh-100 bg-light pt-5"}>
        <ErrorBoundary fallback={<FallbackPage/>}>
            <RouterProvider router={router}/>
        </ErrorBoundary>
        <div className={"text-center"}>
            Copyright © 2016-{new Date().getFullYear()} Land of Gods All rights reserved.
        </div>
    </div>
}

export default App;
