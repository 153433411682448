import Carousel from "../components/Carousel";

const HomeContent = () => {
    return <div>
        <div className={"bg-white p-4 mt-3 shadow-sm rounded"}>
            <Carousel/>
        </div>
        <div className={"bg-white p-4 mt-3 shadow-sm rounded"}>
            <div className={"d-flex flex-wrap"}>
                <div>
                    <div>Welcome to the Land of Gods, a Minecraft experience unlike any other. Our server boasts a
                        vibrant and
                        inclusive community, where players of all levels come together to explore, build, and thrive. We
                        prioritize
                        fairness and balance, offering custom plugins that introduce unique gameplay elements and items
                        you
                        won't
                        find elsewhere. Every detail, from our awe-inspiring spawn point to resource distribution, has
                        been
                        carefully crafted to provide you with the ultimate Minecraft adventure. Whether you're a
                        seasoned pro or
                        just starting your journey, our community invites you to join us in discovering the boundless
                        creativity
                        and
                        unforgettable adventures that await in the Land of Gods.
                    </div>
                </div>
                <div>
                    <div>
                        <iframe title={"discord integration"}
                                src="https://discord.com/widget?id=314181027692281868&theme=dark" width="350"
                                height="500"
                                allowtransparency="true" frameBorder="0"
                                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default HomeContent;